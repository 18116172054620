import {AFD} from 'devegram-javascript-util'
const baseUrl = '/xapi/Accounting/Asset';
const moduleAjaxService = 'backend-service'
const api = {
    namespaced: true,
    state: {
        list: {},
        dropDownList: [],
        pageChunkSize: 24,
        userDataAuthFields: {}
    },
    actions: {
        async dashboardInfo (context, params) {
            let api = {
                verb: 'get',
                service: moduleAjaxService,
                url: baseUrl + '/dashboardInfo'
            };

            return new Promise((resolve, reject) => {
                context.dispatch('ajaxManager/api', api, {root: true}).then(r => {
                    resolve(r.data);
                }).catch(e => {
                    reject(e);
                });
            })
        },
        async userDataAuthFields (context, params) {
            let formName = params.name
            if (context.state.userDataAuthFields.hasOwnProperty(formName)) return context.state.userDataAuthFields[formName]
            let api = {
                verb: 'get',
                service: moduleAjaxService,
                url: baseUrl + '/userDataAuthFields/' + encodeURIComponent(formName)
            };

            return new Promise((resolve, reject) => {
                context.dispatch('ajaxManager/api', api, {root: true}).then(r => {
                    context.state.userDataAuthFields[formName] = r.data
                    resolve(r.data);
                }).catch(e => {
                    reject(e);
                });
            })
        },
        /* CRUD APIs */
        async create(context, params) {
            let api = {
                verb: 'post',
                service: moduleAjaxService,
                url: baseUrl + '/store',
                data: AFD(params.data)
            };

            return new Promise((resolve, reject) => {
                context.dispatch('ajaxManager/api', api, {root: true}).then(r => {
                    context.commit('RESET_CACHE')
                    // context.state.dropDownList.push({value: r.data.id, label: r.data.id})
                    // context.state.list.push({value: r.data.id, label: r.data.id})
                    resolve(r);
                }).catch(e => {
                    reject(e);
                })
            })
        },
        async update(context, params) {
            let api = {
                verb: 'post',
                service: moduleAjaxService,
                url: baseUrl + '/update/' + params.id,
                data: AFD(params.data),
                noLoadingScreen: true
            };

            return new Promise((resolve, reject) => {
                context.dispatch('ajaxManager/api', api, {root: true}).then(r => {
                    context.commit('RESET_CACHE')
                    resolve(r);
                }).catch(e => {
                    reject(e);
                });
            })
        },
        async remove(context, params) {
            let api = {
                verb: 'post',
                service: moduleAjaxService,
                url: baseUrl + '/remove/' + params.id
            };

            return new Promise((resolve, reject) => {
                context.dispatch('ajaxManager/api', api, {root: true}).then(r => {
                    context.commit('RESET_CACHE')
                    resolve(r);
                }).catch(e => {
                    reject(e);
                });
            })
        },
        async multipleDelete(context, params) {
            let api = {
                verb: 'post',
                service: moduleAjaxService,
                url: baseUrl + '/multipleDelete',
                data: AFD(params.data)
            };

            return new Promise((resolve, reject) => {
                context.dispatch('ajaxManager/api', api, {root: true}).then(r => {
                    context.commit('RESET_CACHE')
                    resolve(r);
                }).catch(e => {
                    reject(e);
                });
            })
        },
        async get(context, params) {
            let api = {
                verb: 'get',
                service: moduleAjaxService,
                url: baseUrl + '/get/' + params.id
            };

            return new Promise((resolve, reject) => {
                context.dispatch('ajaxManager/api', api, {root: true}).then(r => {
                    resolve(r.data);
                }).catch(e => {
                    reject(e);
                });
            })
        },
        async list(context, params) {
            let page = "page=" + (params.page)? params.page : 1
            // if (context.state.list[page]) {return context.state.list[page]}
            let noSearch = (params.search == undefined || params.search == '')
            let pageChunk = '&page-chunk=' + context.state.pageChunkSize
            let clientId = '&client-id=' + params.clientId
            let search = "&search=" + encodeURIComponent((params.hasOwnProperty('search'))? params.search : '')
            let query = "?" + page + pageChunk + search + clientId
            let api = {
                verb: 'get',
                service: moduleAjaxService,
                url: baseUrl + '/list' + query,
                data: null
            };

            return new Promise((resolve, reject) => {
                context.dispatch('ajaxManager/api', api, {root: true}).then(r => {
                    // if (noSearch) context.state.list[page] = r.data

                    resolve(r.data);
                }).catch(e => {
                    reject(e);
                });
            })
        },
        async getDropDownList (context, params = {}) {
            if (context.state.dropDownList.length) return context.state.dropDownList

            let query = ''
            if (params.hasOwnProperty('valueKey')) {
                query = '?value_key=' + params.valueKey
            }
            let api = {
                verb: 'get',
                service: moduleAjaxService,
                url: baseUrl + '/dropDownList' + query,
                data: null
            };

            return new Promise((resolve, reject) => {
                context.dispatch('ajaxManager/api', api, { root: true }).then(r => {
                    context.state.dropDownList = r.data
                    resolve(r.data);
                }).catch(e => {
                    reject(e);
                });
            })
        },

        /* Files APIs */
         async updateFile(context, params) {
            let api = {
                verb: 'post',
                service: moduleAjaxService,
                url: baseUrl + '/updateFile/' + params.id,
                data: AFD(params.data)
            };

            return new Promise((resolve, reject) => {
                context.dispatch('ajaxManager/api', api, {root: true}).then(r => {
                    resolve(r);
                }).catch(e => {
                    reject(e);
                })
            })
        },
        async updateGallery(context, params) {
            let api = {
                verb: 'post',
                service: moduleAjaxService,
                url: baseUrl + '/galleryImage/' + params.id,
                data: AFD(params.data)
            };

            return new Promise((resolve, reject) => {
                context.dispatch('ajaxManager/api', api, {root: true}).then(r => {
                    resolve(r);
                }).catch(e => {
                    reject(e);
                })
            })
        },
        async removeGalleryImage(context, params) {
            let api = {
                verb: 'delete',
                service: moduleAjaxService,
                url: baseUrl + '/galleryImage/' + params.id + '/' + params.parentId + '/' + params.fileId,
                data: AFD(params.data)
            };

            return new Promise((resolve, reject) => {
                context.dispatch('ajaxManager/api', api, {root: true}).then(r => {
                    resolve(r);
                }).catch(e => {
                    reject(e);
                })
            })
        },
    },
    mutations: {
        RESET_CACHE (state, params) {
            state.list = {}
            state.dropDownList = []
        }
    }
};

export default api
